<template>
  <div class="journal-account">
    <div class="journal-box">
      <div>
        <!-- <el-button @click="Inquire()" type="danger">导入查询</el-button>
        <el-button type="danger" plain>管理员操作日志</el-button> -->
      </div>
      <div class="journal-box-div">
        <div>
          <el-date-picker @change="getSyList" v-model="value1" type="daterange" value-format="yyyy-MM-dd"
            :clearable='false' range-separator="至"   :start-placeholder="value1[0]" :end-placeholder="value1[1]" >
          </el-date-picker>
          <span>操作对象</span>
          <el-select v-model="caozuo_duixiang1" @change="caozuo_duixiang_huanle">
            <el-option v-for="(i,index) in caozuo_list" :key="index" :label="i.label" :value="i.value">
            </el-option>
          </el-select>

          <span>管理员</span>
          <el-select v-model="admin_renyuan" @change="ganliyuan_huanle">

            <el-option v-for="(i,index) in renyuan_list" :key="index" :label="i.user_name" :value="i.user_id">
            </el-option>
          </el-select>


        </div>

      </div>

    </div>
    <template>
      <el-table :data="tableData" style="width: 100%" height="500">
        <el-table-column prop="operate_time" label="时间" width="200"> </el-table-column>
        <el-table-column prop="user_name" label="管理员" width="200"> </el-table-column>
        <el-table-column prop="operate_aim" label="操作对象" width="180">
          <template slot-scope="scope">
            {{filterHandler(scope.row.operate_aim)}}
          </template>
        </el-table-column>
        <el-table-column prop="operate_desc" label="操作详情"> </el-table-column>
        <!-- <el-table-column prop="incomplete" label="错误信息"> </el-table-column> -->
      </el-table>



    </template>

    <el-pagination layout="prev, pager, next" :total="tableal" @current-change="handleCurrentChange" :page-size='10'>
    </el-pagination>
  </div>
</template>

<script>
  import {
    query_ent_sub_manager_list,
    query_ent_primary_manager_detail,
    get_system_logs_list,
    query_import_log_list,
    query_operate_log_user_list
  } from '@api/pulic'
  export default {
    data() {
      return {
        caozuo_duixiang1:'0',
        value1: [],
        tableData: [],
        options: [],
        valueB: '',
        tableal: 1,
        renyuan_list: [],
        admin_renyuan: null,
        caozuo_duixiang: '',
        caozuo_list: [
          {value: '0', label: '全部'},
          {value: '1', label: '目标'},
          {value: '2',label: '点单'},
          // {value: '3',label: '烹饪'},
          // {value: '4',label: '备餐'},
          // {value: '5',label: '采购'},
          // {value: '6',label: '库房'},
          // {value: '7',label: '供应商'},
          {value: '8',label: '打卡'},
          // {value: '9',label: '激励币'},
          {value: '10',label: '会员'},
          {value: '11',label: '收银'},
          // {value: '12',label: '清洁'},
          {value:'20',label:'web端-基本信息设置'},
          {value:'21',label:'web端-功能权限'},
          {value:'22',label:'web端-目标'},
          {value:'23',label:'web端-营销'},
          {value:'24',label:'web端-采购'},
          {value:'25',label:'web端-仓库',},
          {value:'26',label:'web端-菜品'},
          {value:'27',label:'web端-桌台'},
          {value:'28',label:'web端-人事'},
          // {value:'29',label:'web端-财务'},
          {value:'30',label:'web端-审批'},
          {value:'31',label:'web端-数据中心'},
          {value:'32',label:'web端-硬件设置'},
          {value:'33',label:'web端-会员管理'},
          {value:'34',label:'web端-支付设置'},

        ],
        ZGLY: {}

      }
    },
    mounted() {
       query_operate_log_user_list({
        data: {
          ent_id: this.$store.state.ent_id
        }
      }).then((res) => {
        this.renyuan_list = res.body.data
        console.log(res,'sssssssssssssssssssssss')
      })
    },
    created() {
      query_operate_log_user_list({
        data: {
          ent_id: this.$store.state.ent_id
        }
      }).then((res) => {
        this.renyuan_list = res.body.data
      })
       var aData = new Date();
      // 获取当前月日
      var minute = aData.getMonth() + 1;
      var second = aData.getDate();
      minute = minute < 10 ? ('0' + minute) : minute;
      second = second < 10 ? ('0' + second) : second;
      this.tiem_Medium = aData.getFullYear() + "-" + minute + "-" + second// 获取当前日期
 
      console.log(this.tiem_Medium)

    
 
      this.value1[0] =  this.tiem_Medium
      this.value1[1] =  this.tiem_Medium
      
      // this.getSyList()
      // this.GetSyLList()
      // this.QuerySubManagement()
      this.get_system_logs_list()
    },
    methods: {
      get_system_logs_list(){
          get_system_logs_list({
              data: {
                ent_id: this.$store.state.ent_id,
                date_begin: this.value1[0],
                date_end: this.value1[1],
                page_index: '1',
                page_size: '10'
              }
            }).then(res => {
              this.tableData = res.body.data.list
              this.tableal = res.body.data.total
              console.log(res)
            })
      },
      ganliyuan_huanle(val) {
        get_system_logs_list({
          data: {
            ent_id: this.$store.state.ent_id,
            date_begin: this.value1[0],
            date_end: this.value1[1],
            page_index: '1',
            page_size: '10',
            user_id: this.admin_renyuan ?  this.admin_renyuan : null
            // staff_id: this.admin_renyuan
          }
        }).then(res => {
          this.tableData = res.body.data.list
        })
      },
      caozuo_duixiang_huanle(val) {
          this.val = val
        get_system_logs_list({
          data: {
            ent_id: this.$store.state.ent_id,
            date_begin: this.value1[0],
            date_end: this.value1[1],
            page_index: '1',
            page_size: '10',
            staff_id: this.admin_renyuan ? this.admin_renyuan : null,
              operate_aim:this.caozuo_duixiang1
          }
        }).then(res => {
          this.tableData = res.body.data.list
        })
      },
      handleCurrentChange(val) {
        this.val = val
        get_system_logs_list({
          data: {
            ent_id: this.$store.state.ent_id,
            date_begin: this.value1[0],
            date_end: this.value1[1],
            page_index: String(val),
            page_size: '10',
            staff_id: this.admin_renyuan ? this.admin_renyuan : null
          }
        }).then(res => {
          this.tableData = res.body.data.list
        })
      },
      filterHandler(value) {
        let label = ''
        this.caozuo_list.map(item=>{
          if(value == item.value) {
            label = item.label
          }
        })
        return label

        // if (value == '-1') {
        //   return '全部'
        // } else if (value == '1') {
        //   return '目标'
        // } else if (value == '2') {
        //   return '点单'
        // // } else if (value == '3') {
        // //   return '烹饪'
        // // } else if (value == '4') {
        // //   return '备餐'
        // // } else if (value == '5') {
        // //   return '采购'
        // // } else if (value == '6') {
        // //   return '库房'
        // // } else if (value == '7') {
        // //   return '供应商'
        // } else if (value == '8') {
        //   return '打卡'
        // // } else if (value == '9') {
        // //   return '激励币'
        // } else if (value == '10') {
        //   return '会员'
        // } else if (value == '11') {
        //   return '收银'
        // // } else if(value == '12'){
        // //    return '清洁'


        // }else if(value == '20'){
        //    return 'web端-基本信息设置'
        // }else if(value == '21'){
        //    return 'web端-功能权限'
        // }else if(value == '22'){
        //    return 'web端-目标'
        // }else if(value == '23'){
        //    return 'web端-营销'
        // }else if(value == '24'){
        //    return 'web端-采购'
        // }else if(value == '25'){
        //    return 'web端-仓库'
        // }else if(value == '26'){
        //    return 'web端-菜品'
        // }else if(value == '27'){
        //    return 'web端-桌台'
        // }else if(value == '28'){
        //    return 'web端-人事'
        // // }else if(value == '29'){
        // //    return 'web端-财务'
        // }else if(value == '30'){
        //    return 'web端-审批'
        // }else if(value == '31'){
        //    return 'web端-数据中心'
        // }else if(value == '32'){
        //    return 'web端-硬件设置'
        // }else if(value == '33'){
        //    return 'web端-会员管理'
        // }else if(value == '34'){
        //    return 'web端-支付设置'
        // }
      },
      getSyList() {
        get_system_logs_list({
          data: {
            ent_id: this.$store.state.ent_id,
            date_begin: this.value1[0],
            date_end: this.value1[1],
            page_index: '1',
            page_size: '10'
          }
        }).then(res => {
          this.tableData = res.body.data.list
          this.tableal = res.body.data.total
        })
      },

    }
  }

</script>

<style scoed lang="scss">
  .el-main {

    background: #f6f6fa;
  }

  .journal-account {
    width: 100%;
    background: #fff;
    padding: 44px 0 100px 0;
    -moz-box-shadow: 0px 0px 10px #e8e8eb;
    -webkit-box-shadow: 0px 0px 10px #e8e8eb;
    box-shadow: 0px 0px 10px #e8e8eb;
    border-radius: 15px;
    margin: 30px 0;
    .journal-box {
      padding: 0 0 0 40px;

      & div>.el-button--danger:first-of-type {
        margin-right: 60px;
      }

      & div>.el-button--danger:last-of-type {
        background: #fff;
        color: #fc4452;
        padding: 14px 26px;

        &:hover {
          color: #fc4452;
        }
      }
    }

    .journal-box-div {
      display: flex;
      margin: 43px 0 21px 0;

      &>div {
        margin-right: 100px;

        &>span {
          color: #333333;
          font-size: 16px;
          margin: 0 20px 0 50px;
        }
      }
    }
  }

</style>
